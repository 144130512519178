export const COMMUNITY_USER_ACTION_BUTTON_CONNECT_FRAGMENT = `
  fragment communityUserActionButtonConnectFragment on CommunityUser {
    id
    uid
    firstName
    lastName
    _ourConnection(myUid: "%authUser%") {
      uid
      linkState
      connectedUser {
        uid
        firstName
        lastName
        pictureFileResource {
          schemaCode
          path
        }
      }
      user {
        id
        uid
        firstName
        lastName
        pictureFileResource {
          schemaCode
          path
        }
      }
    }
    _actions (actions: ["CAN_CONNECT"]) {
      value
      key
    }
  }
`;
